import { checkStatus, handleError } from '../../utils/api';
import { getAuthTokenAsync } from '../../utils/authToken';
import { ValidateUser } from '../../interfaces/login';
import {
  USERNAME_OR_EMAIL_REQUIRED,
  INCORRECT_USERNAME_OR_EMAIL,
  UNEXPECTED_ERROR,
} from '../../constants/errors';
import apiFetch from '../core/apiFetch';
import { mapUser } from './helpers';
import { AccountRecoveryProps } from './accountRecoveryAsync';

interface ValidateUserFormProps {
  userIdentifier: string;
  userPersonalData?: AccountRecoveryProps;
}

interface Response {
  users?: ValidateUser[];
  error?: {
    code: string;
    message: string[];
  };
}

const multipleAccountsAsync = async ({
  userIdentifier,
  userPersonalData,
}: ValidateUserFormProps): Promise<Response> => {
  try {
    let url = `api/v2/validate_user/multiple_accounts?validate_user[user_identifier]=${encodeURIComponent(
      userIdentifier
    )}`;
    if (userPersonalData) {
      const { firstName, lastName, birthdate } = userPersonalData;
      url += `&personal_data[first_name]=${encodeURIComponent(
        firstName
      )}&personal_data[last_name]=${encodeURIComponent(
        lastName
      )}&personal_data[birthdate]=${encodeURIComponent(birthdate)}`;
    }

    const authToken = await getAuthTokenAsync();
    const resp = await apiFetch(authToken, url, { method: 'GET' });

    await checkStatus(resp);

    const { users } = await resp.json();
    return { users: users.map((user) => mapUser(user)) };
  } catch (error) {
    const { message, response } = error;

    if (response?.status === 400) {
      const payload = await response.json();
      return {
        error: {
          code: USERNAME_OR_EMAIL_REQUIRED,
          message: payload.errors || [],
        },
      };
    }

    if (response?.status === 401) {
      const payload = await response.json();
      return {
        error: {
          code: INCORRECT_USERNAME_OR_EMAIL,
          message: payload.errors || [],
        },
      };
    }

    handleError('unexpected status - validate user', error);
    return {
      error: {
        code: UNEXPECTED_ERROR,
        message: [message],
      },
    };
  }
};

export default multipleAccountsAsync;
