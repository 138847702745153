import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Flex, Input, InputProps } from 'mdlkit';
import { Show, Hide } from '../../../core/icons';

const TransparentButton = styled.button`
  color: ${({ theme }) => theme.colors.transparent};
  background-color: ${({ theme }) => theme.colors.transparent};
  outline: none;
  border: none;
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: auto auto auto -32px;

  &:focus {
    > svg > g > path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const ClickableShow = styled(Show)`
  pointer-events: none;
`;

const ClickableHide = styled(Hide)`
  pointer-events: none;
`;

export type PasswordProps = InputProps;

const Password: FunctionComponent<PasswordProps> = (props: PasswordProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Flex width={1} align="center">
      <Input
        {...props}
        autoCapitalize="off"
        type={showPassword ? 'text' : 'password'}
      />
      <TransparentButton
        type="button"
        onClick={toggleShowPassword}
        id="showHide"
        aria-label="Show and Hide Password"
      >
        {showPassword ? <ClickableShow /> : <ClickableHide />}
      </TransparentButton>
    </Flex>
  );
};

// @ts-ignore
Password.isField = true;
export default Password;
