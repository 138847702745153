import moment from 'moment';
import { ValidateUser, ValidateUserApi } from '../../interfaces/login';
// eslint-disable-next-line import/prefer-default-export
export const mapUser = ({
  id,
  username,
  email,
  user_type,
  last_signed_in,
  affiliation,
  is_locked,
  token,
}: ValidateUserApi): ValidateUser => ({
  id,
  username,
  email,
  userType: user_type,
  is_locked,
  token,
  lastSignedIn:
    last_signed_in && moment(last_signed_in).format('MMMM DD, YYYY'),
  affiliation: affiliation && {
    id: affiliation.id,
    name: affiliation.name,
    logo: affiliation.logo,
  },
});
