import apiFetch from '../core/apiFetch';
import { checkStatus, handleError } from '../../utils/api';
import { AuthCodeSession } from '../../interfaces/login';
import {
  AUTH_CODE_SESSION_ERROR,
  TOO_MANY_REQUESTS,
} from '../../constants/errors';
import { getAuthTokenAsync } from '../../utils/authToken';

const validateAuthCodeAsync = async ({
  authCodeId,
  userId,
  code,
}: AuthCodeSession): Promise<{
  error?: { code: string; payload?: any };
  userPhone?: string;
}> => {
  const url = `api/v1/auth_code/sessions/create_without_auth`;

  try {
    const authToken = await getAuthTokenAsync();

    const response = await apiFetch(authToken, url, {
      method: 'POST',
      body: JSON.stringify({
        session: {
          auth_code_id: authCodeId,
          user_id: userId,
          code,
        }, // eslint-disable-line @typescript-eslint/camelcase
      }),
    });

    await checkStatus(response);

    const {
      session: { user_phone },
    } = await response.json();
    return { userPhone: user_phone };
  } catch (error) {
    const { response } = error;

    if (response.status === 422) {
      return {
        error: {
          code: AUTH_CODE_SESSION_ERROR,
          payload: { code: 'Invalid Code. Try again or resend code' },
        },
      };
    }

    if (response.status === 429) {
      return {
        error: {
          code: TOO_MANY_REQUESTS,
          payload: {
            code:
              "We're sorry, but you have sent too many requests.  Please try again later.",
          },
        },
      };
    }

    handleError('unexpected status - create auth code session', error);
    return { error: { code: AUTH_CODE_SESSION_ERROR } };
  }
};

export default validateAuthCodeAsync;
